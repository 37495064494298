module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/runner/work/triangular-landing-page/triangular-landing-page/static/locales","languages":["en","de","es"],"defaultLanguage":"en","i18nextOptions":{"debug":false,"interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/disclaimers/:lang/:slug","excludeLanguages":["en","de","es"],"getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TRIANGULAR-PST GMBH & CO. KG","short_name":"Triangular-pod - The Process On Demand Platform","icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"57701e88755ffe248c7b7172c6dd3696"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
